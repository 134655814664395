import { h } from 'preact';
import { Router, route } from 'preact-router';

import Header from './header';
import { config } from '../config';
import { account } from '../services/account.service';
import { storageHandler } from '../util/storage_handler';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import QRscanner from '../routes/qrscanner';
import LoginSMS from '../routes/login-sms';
import Register from '../routes/register';
import ContactUs from '../routes/contact-us';
import PrizeRedeem from '../routes/prize-redeem';
import EarnPoints from '../routes/earn-points';


async function isAuthenticated() {
	const urlParams = new URLSearchParams(location.search);
	const code = urlParams.get("code");
	const state = urlParams.get("state");
	const parts = location.pathname.split('/');
	const uid = parts.pop() || parts.pop(); 
	if(code){
		storageHandler.localStorageSet("code", code);
	}
	if(state){
		storageHandler.localStorageSet("state", state);
	}
	if(uid && parts.findIndex(r => r === "login") > -1){
		storageHandler.localStorageSet("uid", uid);
	}
	if(code && state){
		let authLine = await account.login({
			"line_login": true,
			"otp":"0000",
			"uid": storageHandler.localStorageGet("uid"),
			"code": code,
			"state": state
		});
		if(authLine.success){
			storageHandler.localStorageSet("token", authLine.token);
		}
        history.pushState({}, "Hide", location.origin);
	}
	return typeof storageHandler.localStorageGet("token") !== "undefined" && storageHandler.localStorageGet("token") !== "undefined";
}

const handleRoute = async e => {
	const isAuthed = await isAuthenticated();
	if (!e.url.includes(`/login/${storageHandler.localStorageGet("uid")}`)) {
		if (!isAuthed) {
			route(`/login/${storageHandler.localStorageGet("uid")}`, true);
		}
	} else {
		if (isAuthed) {
			route('/', true);
		}
	}
};

const App = () => (
	<div id="app">
		<Header />
		<Router onChange={handleRoute}>
			<Home path="/" />
			<Profile path="/profile/" user="me" />
			<Profile path="/profile/:user" />
			<QRscanner path="/qrscanner" />
			<LoginSMS path="/login/:uid"/>
			<Register path="/register"/>
			<ContactUs path="/contact-us" />
			<PrizeRedeem path="/prize-redeem" />
			<EarnPoints path="/earn-points" />
		</Router>
	</div>
)

export default App;
