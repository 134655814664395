import { api_fetch } from "../util/api_fetch";
import { config } from "../config.js";
import { storageHandler } from "../util/storage_handler";

const account = {
    login: async function(body){
        //Subject for change
        const params = `/login`;
        return (await (api_fetch.post(`${config.baseURL}`, {}, { }, params, body))).json();
    },
    loginSMS: async function(id, query){
        //Subject for change
        const params = `/login/${id}`;
        return (await (api_fetch.get(`${config.baseURL}`, {}, query, params, {}))).json();
    },
    getAccount: async function(account_id){
        //Subject for change
        const params = `/account/${account_id}`;
        return (await ((api_fetch.get(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, {}, params, {})))).json();
    },
    earnPoints: async function(account_id, location){
        //Subject for change
        const params = `/point/${account_id}`;
        return (await ((api_fetch.get(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, location, params, {})))).json();
    },
    claimRewards: async function(body){
        //Subject for change
        const params = `/reward`;
        return (await (api_fetch.post(`${config.baseURL}`, {}, { claim: true }, params, body))).json();
    },
    transact: async function(body){
        //Subject for change
        const params = `/transact`;
        return (await (api_fetch.post(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, { }, params, body))).json();
    },
    modifyAccount: async function(account_id, body){
        //Subject for change
        const params = `/account/${account_id}`;
        return (await ((api_fetch.patch(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, {}, params, body)))).json();
    },
    deleteAccount: async function(account_id){
        //Subject for change
        const params = `/account/${account_id}`;
        return (await (api_fetch.delete(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, {}, params, {}))).json();
    },
    listNews: async function(){
        //Subject for change
        const params = `/news`;
        return (await ((api_fetch.get(`${config.baseURL}`, {
            Authorization: `Bearer ${storageHandler.localStorageGet("token")}`
        }, {}, params, {})))).json();
    },
    sendEmail: async function(body){
        //Subject for change
        const params = `/email`;
        return (await (api_fetch.post(`${config.baseURL}`, {}, { }, params, body))).json();
    }
}

export { account };